import {
  Create,
  DeleteButton,
  PasswordInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
} from 'react-admin';
import CryptoJS from 'crypto-js';
import { salt } from '../auth';
import { validateForm } from './UserEdit';

const UserSaveButton = (props: any) => {
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect('/users');
  };

  return <SaveButton {...props} mutationOptions={{onSuccess}} />;
};

const UserCreateToolbar = () => (
  <Toolbar>
    <UserSaveButton />
    <DeleteButton />
  </Toolbar>
);

const UserCreate = () => (
    <Create
      title="用户"
      transform={(data:any) => {
        delete data.confirm_password
  
        if (data.password) {
          data.password = CryptoJS.PBKDF2(data.password, salt).toString(CryptoJS.enc.Hex);
        }
  
        return data;
      }}
    >
      <UserForm />
    </Create>
  );
  
  const UserForm = () => {
    return (
      <SimpleForm
        validate={validateForm}
        toolbar={<UserCreateToolbar />}
      >
        <TextInput label="用户名" source="username" />
        <TextInput label="昵称" source="nickname" />
        <PasswordInput label="密码" source="password" value="" />
        <PasswordInput label="确认密码" source="confirm_password" value="" />
      </SimpleForm>
    );
  };
  
  export default UserCreate;