import { Box, Card, Divider, Typography } from "@mui/material";
import { createElement, FC, ReactNode } from "react";

interface Props {
  icon: FC<any>;
  title?: string;
  subtitle?: string | number;
  children?: ReactNode;
}

const CardWithIcon = ({ icon, title, subtitle, children }: Props) => (
  <Card
    sx={{
      minHeight: 52,
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      '& a': {
        textDecoration: 'none',
        color: 'primary',
      },
    }}
  >
    <Box
      sx={{
        overflow: 'inherit',
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
          color: theme => 
            theme.palette.mode === 'dark'
              ? 'primary' 
              : '#dc2440',
        },
      }}
    >
      <Box width="3em" className="icon">
        {createElement(icon, { fontSize: 'large' })}
      </Box>
      <Box textAlign={"right"}>
        <Typography color="textSecondary">{title}</Typography>
        <Typography variant="h5" component="h2">
          {subtitle || ' '}
        </Typography>
      </Box>
    </Box>
    {children && <Divider />}
    {children}
  </Card>
);

export default CardWithIcon;