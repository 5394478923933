import { AuthProvider } from 'react-admin';
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';

export const salt = '+u&6~U;RTvimSbfPOzbh';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const saltedPassword = CryptoJS.PBKDF2(password, salt).toString(CryptoJS.enc.Hex);

    const request = new Request('/api/v1/login', {
      method: 'POST',
      body: JSON.stringify({ username, password: saltedPassword }),
      headers: new Headers({ 'Content-type': 'application/json' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json()
      }).then(auth => {
        localStorage.setItem('auth', JSON.stringify(auth.auth));

        // return Promise.resolve();
      }).catch(err => {
        throw err;
      });
  },

  logout: async () => {
    const request = new Request('/api/v1/logout', {
      method: 'POST',
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        const cookies = new Cookies();
        cookies.remove('PAN-ADMIN');

        return response.json()
      }).then(() => {
        return Promise.resolve();
      }).catch(() => {
        throw new Error('Network error');
      });
  },

  checkAuth: () => {
    const cookies = new Cookies();

    return cookies.get('PAN-ADMIN')
      ? Promise.resolve()
      : Promise.reject();
  },

  checkError: (error) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      const cookies = new Cookies();
      cookies.remove('PAN-ADMIN');

      return Promise.reject();
    }

    return Promise.resolve();
  },

  getIdentity: () => {
    try {
      const auth = JSON.parse(String(localStorage.getItem('auth')));

      return Promise.resolve({
        id: auth.id,
        fullName: auth.nickname,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    return Promise.resolve();
  },
}

export default authProvider
