import CheckIcon from '@mui/icons-material/Check';
import { Button, useDataProvider, useNotify, useRecordSelection, useRefresh } from 'react-admin';
import { useMutation } from 'react-query';

const OrderFinishBulkButton = ( props: { paymentMethod: number, label?: string }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const selected = useRecordSelection('orders');
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(
    () => dataProvider.finishOrders('orders', { ids: selected[0], method: props.paymentMethod }), 
    {
      onSuccess: () => {
        notify('操作成功');
        refresh();
        selected[1].clearSelection();
      }
    }
  );

  return <Button 
    label={props.label ?? "设为已交易"}
    startIcon={<CheckIcon />} 
    onClick={() => mutate()} 
    disabled={isLoading} 
  />;

  /*
  <BulkUpdateButton
    icon={<CheckIcon/>}
    label="设为已下订"
    data={{
      status: 1
    }}
  />
  */
};

export default OrderFinishBulkButton;