import PaymentMethodIcon from '@mui/icons-material/Money';

import PaymentMethodList from './PaymentMethodList';
import PaymentMethodCreate from './PaymentMethodCreate';


export default {
  list: PaymentMethodList,
  create: PaymentMethodCreate,

  icon: PaymentMethodIcon,
};
