import CheckIcon from '@mui/icons-material/Check';
import { Button, useDataProvider, useNotify, useRecordSelection, useRefresh } from 'react-admin';
import { useMutation } from 'react-query';

const OrderConfirmBulkButton = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const selected = useRecordSelection('orders');
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(
    () => dataProvider.confirmOrders('orders', { ids: selected[0] }), 
    {
      onSuccess: () => {
        notify('操作成功');
        refresh();
        selected[1].clearSelection();
      }
    }
  );

  return <Button 
    label="设为已下订" 
    startIcon={<CheckIcon />} 
    onClick={() => mutate()} 
    disabled={isLoading} 
  />;
};

export default OrderConfirmBulkButton;