import { useMediaQuery, Theme } from "@mui/material";
import { Fragment, useCallback } from "react";
import { 
  Count, 
  CreateButton, 
  Datagrid, 
  DateField, 
  DateInput, 
  FilterButton, 
  List, 
  NumberField, 
  ReferenceField, 
  SelectColumnsButton, 
  SelectField, 
  TextField, 
  TopToolbar, 
  useListContext 
} from "react-admin";

const ListAction = () => {
  let role = JSON.parse(localStorage.getItem("auth") ?? "{type:\"未知\"}").type

  if (role === "分销商" || role === "观察者") {
    return (
      <TopToolbar>
      </TopToolbar>
    );
  } else {
    return (
      <TopToolbar>
        <CreateButton />
      </TopToolbar>
    );
  }
};

const PaymentMethodList = () => (
  <List
    sort={{ field: 'id', order: 'ASC' }}
    perPage={25}
    actions={<ListAction />}
  >
    <PaymentMethodDatagrid />
  </List>
);

const PaymentMethodDatagrid = () => {
  // const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Datagrid isRowSelectable={ () => false }>
      <TextField label="编号" source="id" />
      <TextField label="名称" source="name" sortable={false}/>
    </Datagrid>  
  );
};

export default PaymentMethodList;