import DownloadIcon from '@mui/icons-material/Download';
import { Button, useListContext, useListSortContext, useNotify, useTranslate } from 'react-admin';

const OrderExportButton = () => {
  const { filterValues, sort, selectedIds } = useListContext();
  const translate = useTranslate();
  const notify = useNotify()

  return <Button
    label={translate('ra.action.export')}
    startIcon={<DownloadIcon />}
    onClick={() => {
      let filename = 'unknown';

      let url = '/api/v1/orders/export?';
      let querysArray = [];
      for (const k in filterValues) {
        querysArray.push(`${k}=${filterValues[k]}`);
      }

      let querys = url + querysArray.join('&');

      if (sort) {
        querys = querys + '&_order=' + sort.order + '&_sort=' + sort.field;  
      }

      if (selectedIds.length > 0) {
        querys = querys + '&_id=' + selectedIds.join(',');
      }

      fetch(querys)
        .then(resp => {
          if (!resp.ok) {
            throw Error(resp.statusText);
          }

          const header = resp.headers.get('content-disposition');
          if (header) {
            const parts = header.split(';');
            filename = parts[1].split('=')[1];
          }

          return resp.blob();
        })
        .then(blob => {
          if (blob != null) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        })
        .catch(err => {
          notify(err, { type: 'error' })
        });
    }}
  />;
};

export default OrderExportButton;