import { Admin, CreateParams, fetchUtils, Resource, UpdateParams } from 'react-admin';
import authProvider from './auth';
import jsonServerProvider from 'ra-data-json-server';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chineseMessages from 'ra-language-chinese';

import orders from './orders';
import payment_methods from './payment_methods';
import users from './users';
import daily_infos from './daily_infos';

import Dashboard from './Dashboard';

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  options.credentials = 'include';

  return fetchUtils.fetchJson(url, options);
};

const convertFileToBase64 = (file: any) => 
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  }); 

// const dataProvider = jsonServerProvider('https://jap.fredfucks.me/api/v1', httpClient);
const dataProvider = jsonServerProvider('/api/v1', httpClient);

const myDataProvider = {
  ...dataProvider,
  create: (resource: any, params: any) => {
    if (resource !== 'orders') {
      return dataProvider.create(resource, params);
    }

    let img = params.data.img;
    const isNewImg = img && img.rawFile instanceof File;

    if (isNewImg) {
      return convertFileToBase64(img)
        .then(img => dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            image_src: img,
          }
        } as CreateParams<any>));
    } else {
      return dataProvider.create(resource, params);
    }
  },
  update: (resource: any, params: any) => {
    if (resource !== 'orders') {
      return dataProvider.update(resource, params);
    }

    let img = params.data.img;
    const isNewImg = img && img.rawFile instanceof File;

    if (isNewImg) {
      return convertFileToBase64(img)
        .then(img => dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            image_src: img,
          }
        } as UpdateParams<any>));
    } else {
      return dataProvider.update(resource, params);
    }
  },
  confirmOrder: (resource: string, props: any) => {
    return fetch(`/api/v1/orders/${props.id}/confirm`, { method: 'PUT' })
      .then(resp => resp.json());
  },
  confirmOrders: (resource: string, props: { ids: ArrayBuffer }) => {
    return fetch(`/api/v1/orders/confirm`, { method: 'PUT', body: JSON.stringify({ 'ids': props.ids }) })
      .then(resp => resp.json());
  },
  finishOrders: (resource: string, props: { ids: ArrayBuffer, method?: number }) => {
    return fetch(`/api/v1/orders/finish`, { method: 'PUT', body: JSON.stringify({ 'ids': props.ids, 'method': props.method }) })
      .then(resp => resp.json());
  },
};

const i18nProvider = polyglotI18nProvider(() => chineseMessages, 'ch');

const App = () => (
  <Admin 
    disableTelemetry
    title="管理后台"
    dashboard={Dashboard}
    dataProvider={myDataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
  >
    <Resource
      name="daily_infos"
      options={{ label: '每日汇总' }}
      {...daily_infos}
    />
    <Resource
      name="orders"
      options={{ label: '订单' }}
      {...orders}
    />
    <Resource 
      name="payment_methods"
      options={{ label: '支付方式' }}
      recordRepresentation={(row) => `${row.name}`}
      {...payment_methods}
    />
    <Resource 
      name="users"
      options={{ label: '用户' }}
      {...users}
    />
  </Admin>
)

export default App;
