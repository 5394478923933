import * as React from 'react';
import {
  DateInput,
  DateTimeInput,
  Edit,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TopToolbar,
} from 'react-admin';

const OrderEdit = () => {
  let role = JSON.parse(localStorage.getItem("auth") ?? "{type:\"未知\"}").type

  if (role === "观察者") {
    return (
      <Edit title="订单" component="div" redirect="list">
        <WatcherOrderForm />
      </Edit>
    );
  } else {
    return (
      <Edit title="订单" component="div" redirect="list">
        <OrderForm />
      </Edit>
    );
  }
};

const OrderForm = () => {
  return (
    <SimpleForm>
      <TextInput disabled label="编号" source="id" />
      <TextInput label="成本" source="cost" parse={v => Number(v)} format={v => Number(v)} />
      <TextInput label="利润" source="profit" parse={v => Number(v)} format={v => Number(v)} />
      <SelectInput label="状态" source="status" choices={[
        { id: 0, name: '未下订' },
        { id: 1, name: '已下订' },
        { id: 2, name: '已交易' },
      ]} />
      <ReferenceInput source="payment_method" reference="payment_methods">
        <SelectInput label="支付手段" helperText="当状态为已交易时生效" />
      </ReferenceInput>
      <DateInput label="下订时间" source="confirmed_at" helperText="当状态为已下订及已交易时生效" />
      <DateInput label="交易时间" source="finished_at" helperText="仅当状态为已交易时生效" />
      <TextInput multiline resettable rows={5} label="备注" source="description" />
      <DateTimeInput disabled label="创建时间" source="created_at" />
      <ImageInput label="截图" source="img" accept="image/*">
        <ImageField 
          source="src" 
          sx={{ '& .RaImageField-image': { maxWidth: 1000, maxHeight: 1000, objectFit: 'contain', width: '100%', height: '100%' } }}
        />
      </ImageInput>
    </SimpleForm>
  );
};

const WatcherOrderForm = () => {
  return (
    <SimpleForm toolbar={false}>
      <TextInput disabled label="编号" source="id" />
      <TextInput disabled label="成本" source="cost" parse={v => Number(v)} format={v => Number(v)} />
      <TextInput disabled label="利润" source="profit" parse={v => Number(v)} format={v => Number(v)} />
      <SelectInput disabled label="状态" source="status" choices={[
        { id: 0, name: '未下订' },
        { id: 1, name: '已下订' },
        { id: 2, name: '已交易' },
      ]} />
      <ReferenceInput source="payment_method" reference="payment_methods">
        <SelectInput disabled label="支付手段" helperText="当状态为已交易时生效" />
      </ReferenceInput>
      <DateInput disabled label="下订时间" source="confirmed_at" helperText="当状态为已下订及已交易时生效" />
      <DateInput disabled label="交易时间" source="finished_at" helperText="仅当状态为已交易时生效" />
      <TextInput disabled multiline resettable rows={5} label="备注" source="description" />
      <DateTimeInput disabled label="创建时间" source="created_at" />
      <ImageField 
        source="img"
        src="src" 
        sx={{ '& .RaImageField-image': { maxWidth: 1000, maxHeight: 1000, objectFit: 'contain', width: '100%', height: '100%' } }}
      />
    </SimpleForm>
  );
};

export default OrderEdit;