import { useMediaQuery, Theme } from "@mui/material";
import { 
  CreateButton, 
  Datagrid, 
  List, 
  TextField, 
  TopToolbar, 
} from "react-admin";

const ListAction = () => {
  let role = JSON.parse(localStorage.getItem("auth") ?? "{type:\"未知\"}").type

  if (role === "分销商" || role === "观察者") {
    return (
      <TopToolbar>
      </TopToolbar>
    );
  } else {
    return (
      <TopToolbar>
        <CreateButton />
      </TopToolbar>
    );
  }

};

const UserList = () => (
  <List
    sort={{ field: 'id', order: 'ASC' }}
    perPage={25}
    actions={<ListAction />}
  >
    <UserDatagrid />
  </List>
);

const UserDatagrid = () => {
  // const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Datagrid 
      isRowSelectable={ () => false }
      rowClick="edit"
    >
      <TextField label="编号" source="id" />
      <TextField label="用户名" source="username" sortable={false}/>
      <TextField label="昵称" source="nickname" sortable={false}/>
    </Datagrid>
  );
};

export default UserList;