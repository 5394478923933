import {
  Edit,
  PasswordInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import CryptoJS from 'crypto-js';
import { salt } from '../auth';

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any;

  if (!values.username) {
    errors.username = 'ra.validation.required';
  }

  if (!values.nickname) {
    errors.nickname = 'ra.validation.required';
  }

  if (values.password && values.password.length < 6) {
    errors.password = '密码应不小于6位';
  }

  if (values.password && values.password !== values.confirm_password) {
    errors.confirm_password = '密码不匹配';
  }

  return errors;
}

const UserEdit = () => (
  <Edit 
    title="用户"
    transform={data => {
      delete data.confirm_password

      if (data.password) {
        data.password = CryptoJS.PBKDF2(data.password, salt).toString(CryptoJS.enc.Hex);
      }

      return data;
    }}
  >
    <UserForm />
  </Edit>
);

const UserForm = () => {
  return (
    <SimpleForm
      validate={validateForm}
    >
      <TextInput disabled label="编号" source="id" />
      <TextInput disabled label="用户名" source="username" />
      <TextInput label="昵称" source="nickname" />
      <PasswordInput label="密码" source="password" value="" />
      <PasswordInput label="确认密码" source="confirm_password" value="" />
    </SimpleForm>
  );
};

export default UserEdit;