import { 
  Datagrid, 
  DateField, 
  List, 
  NumberField, 
  Pagination, 
  TopToolbar, 
} from "react-admin";

const DailyInfoPagination = (props: any) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} {...props} />;

const DailyInfoBulkActionButtons = () => (
  <>
  </>
);

const ListAction = () => (
  <TopToolbar>
  </TopToolbar>
);

const DailyInfoList = () => (
  <List
    sort={{ field: 'date', order: 'DESC' }}
    perPage={50}
    pagination={<DailyInfoPagination />}
    actions={<ListAction />}
  >
    <DailyInfoDatagrid />
  </List>
);

const DailyInfoDatagrid = () => {
  // const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Datagrid
      bulkActionButtons={<DailyInfoBulkActionButtons />}
      isRowSelectable={ () => false }
    >
      <DateField
        label="日期"
        source="date"
      />
      <NumberField
        label="已付款项"
        source="confirmed_cost"
        options={{
          style: 'currency',
          currency: 'CNY',
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }}
        sx={{ fontWeight: 'bold' }}
      />
      <NumberField
        label="应结利润"
        source="confirmed_profit"
        options={{
          style: 'currency',
          currency: 'CNY',
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }}
        sx={{ fontWeight: 'bold' }}
      />
      <NumberField
        label="已结款项"
        source="finished_cost"
        options={{
          style: 'currency',
          currency: 'CNY',
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }}
        sx={{ fontWeight: 'bold' }}
      />
      <NumberField
        label="已结利润"
        source="finished_profit"
        options={{
          style: 'currency',
          currency: 'CNY',
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }}
        sx={{ fontWeight: 'bold' }}
      />
      <NumberField
        label="下订订单数"
        source="confirmed_count"
      />
      <NumberField
        label="已结订单数"
        source="finished_count"
      />
    </Datagrid>  
  );
};

export default DailyInfoList;