import {
  Create, SimpleForm, TextInput,
} from 'react-admin';

const PaymentMethodCreate = () => (
  <Create title="支付方式">
    <PaymentMethodForm />
  </Create>
);

const PaymentMethodForm = () => (
  <SimpleForm>
    <TextInput label="名称" source="name" />
  </SimpleForm>
);

export default PaymentMethodCreate;