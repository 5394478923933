import { Card, CardContent, CardHeader } from "@mui/material";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const CostChart = (props: { costs?: any }) => {
  const { costs } = props;
  if (!costs) return null;

  return (
    <Card style={{ flex: 1 }}>
      <CardHeader title='每日库存金额' />
      <CardContent>
        <div style={{ width: '100%', height: 250 }}>
          <ResponsiveContainer>
            <AreaChart data={costs}>
              <defs>
                <linearGradient
                  id="colorUv"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor="#8884d8"
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor="#8884d8"
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <XAxis
                dataKey='date'
                interval={10}
                // domain={[(dataMin: any) => (dataMin - 5000000 > 0 ? (dataMin - 500000) - (dataMin % 10000) : 0), 'auto']}
                // domain={['auto', 'auto']}
              />
              <YAxis
                dataKey='cost'
                tickFormatter={(x: any, i: number) => {return `${x/10000}万`;}}
                domain={[(dataMin: any) => (dataMin - 5000000 > 0 ? (dataMin - 500000) - (dataMin % 10000) : 0), 'auto']}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <Tooltip
                cursor={{ strokeDasharray:'3 3' }}
                formatter={(value: any) => [`¥ ${value}`, '成本']}
              />
              <Area
                type="monotone"
                dataKey="cost"
                stroke="#8884d8"
                strokeWidth={2}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default CostChart;