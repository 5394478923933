import {
  Create,
  DateInput,
  DateTimeInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslatable, 
} from 'react-admin';

/*
const OrderTitle = () = {
  // const translate = useTranslatable();
  // const record = useRecordContext<Order>();
}
*/

const orderDefaultValue = () => {
  const now = new Date();
  const datetime = now.toISOString();

  return {
    cost: 0,
    profit: 0,
    status: 0,
    confirmed_at: datetime,
    finished_at: datetime,
  };
};

const OrderCreate = () => {
  return (
    <Create title="订单" component="div" redirect="list">
      <OrderForm />
    </Create>
  );
};

const OrderForm = () => {
  return (
    <SimpleForm defaultValues={orderDefaultValue}>
      <TextInput label="成本" source="cost" parse={v => Number(v)} format={v => Number(v)} />
      <TextInput label="利润" source="profit" parse={v => Number(v)} format={v => Number(v)} />
      <SelectInput label="状态" source="status" choices={[
        { id: 0, name: '未下订' },
        { id: 1, name: '已下订' },
        { id: 2, name: '已交易' },
      ]} />
      <ReferenceInput source="payment_method" reference="payment_methods">
        <SelectInput label="支付手段" helperText="当状态为已交易时生效" />
      </ReferenceInput>
      <DateInput label="下订时间" source="confirmed_at" helperText="当状态为已下订及已交易时生效" />
      <DateInput label="交易时间" source="finished_at" helperText="仅当状态为已交易时生效" />
      <TextInput multiline resettable rows={5} label="备注" source="description" />
      <ImageInput label="截图" source="img">
        <ImageField
          source="src" 
          sx={{ '& .RaImageField-image': { maxWidth: 1000, maxHeight: 1000, objectFit: 'contain', width: '100%', height: '100%' } }}
        />
      </ImageInput>
    </SimpleForm>
  );
};

export default OrderCreate;