import { useMediaQuery, useTheme, Tab, Tabs, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import YenIcon from "@mui/icons-material/CurrencyYen";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PercentIcon from '@mui/icons-material/Percent';
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { 
  BulkDeleteButton,
  Button,
  Count, 
  CreateButton, 
  DatagridConfigurable, 
  DateField, 
  DateInput, 
  EditButton, 
  FilterButton, 
  ImageField, 
  List, 
  NumberField, 
  Pagination, 
  ReferenceField, 
  SelectColumnsButton, 
  SelectField, 
  TextField, 
  TopToolbar, 
  useDataProvider, 
  useGetMany, 
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import CardWithIcon from "../utils/CardWithIcon";
import { useMutation } from "react-query";
import OrderConfirmBulkButton from "./OrderConfirmBulkButtons";
import OrderFinishBulkButton from "./OrderFinishBulkButtons";
import OrderExportButton from "./OrderExportButton";

const Spacer = () => <span style={{ width: '1em' }} />

const OrderPagination = (props: any) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500, 1000]} {...props} />;

const OrderListBulkActionButtons = (props: any) => {
  let role = JSON.parse(localStorage.getItem("auth") ?? "{type:\"未知\"}").type

  if (role === "观察者") {
    return <></>
  } else {
    switch (props.filterValues.status) {
      case "-1":    
        return <><BulkDeleteButton /></>
      case "0":
        return <UnconfirmedOrderBulkActionButtions />
      case "1":
        return <ConfirmedOrderBulkActionButtions />
      case "2":
        return <><BulkDeleteButton /></>
      default:
        return <></>
    }
  }
}

const UnconfirmedOrderBulkActionButtions = () => (
  <>
    <OrderConfirmBulkButton />
    <BulkDeleteButton />
  </>
)

const ConfirmedOrderBulkActionButtions = () => {
  let { data } = useGetMany(
    'payment_methods', 
    { ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
  )
  let buttons = []
  if (data) {
    data.sort((a, b) => (a.id - b.id))
    for(const v of data) {
      buttons.push(<OrderFinishBulkButton label={`设为已交易(${v.name})`} paymentMethod={v.id} />)
    }
  }

  return (
    <>
      {buttons}
      <BulkDeleteButton />
    </>
  )
}

const OrderConfirmButton = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(
    () => dataProvider.confirmOrder('orders', { id: record.id }), 
    {
      onSuccess: () => {
        notify('操作成功');
        refresh();
      }
    }
  );

  return <Button label="设为已下订" startIcon={<CheckIcon />} onClick={() => mutate()} disabled={isLoading} />;
};

const ListAction = () => {
  let role = JSON.parse(localStorage.getItem("auth") ?? "{type:\"未知\"}").type

  if (role === "观察者") {
    return (
      <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <OrderExportButton />
      </TopToolbar>
    );
  } else {
    return (
      <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <OrderExportButton />
        <CreateButton />
      </TopToolbar>
    );
  }
};

const OrderList = () => (
  <List
    filterDefaultValues={{ status: '-1' }}
    sort={{ field: 'id', order: 'DESC' }}
    perPage={50}
    pagination={<OrderPagination />}
    filters={orderFilters}
    actions={<ListAction />}
  >
    <TabbedDatagrid />
  </List>
);

const orderFilters = [
  <DateInput label="日期前创建" source="date_lte" />,
  <DateInput label="日期后创建" source="date_gte" />,
  <DateInput label="日期前下订" source="confirmed_date_lte" />,
  <DateInput label="日期后下订" source="confirmed_date_gte" />,
  <DateInput label="日期前完成" source="finished_date_lte" />,
  <DateInput label="日期后完成" source="finished_date_gte" />,
];

const tabs = [
  { id: '-1', name: '全部' },
  { id: '0', name: '未下订' },
  { id: '1', name: '已下订' },
  { id: '2', name: '已交易' },
];

/*
const TabbedDatagrid = () => {
  const [cost, setCost] = useState(0);
  const [profit, setProfit] = useState(0);
  const [total, setTotal] = useState(0);
  const [profitAbility, setProfitAbility] = useState('0%')

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters, data, selectedIds } = listContext;
  
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters && setFilters(
        { ...filterValues, status: value },
        displayedFilters,
        false
      );
    },
    [displayedFilters, filterValues, setFilters]
  );

  useEffect(() => {
    if (selectedIds.length > 0) {
      let cost = 0;
      let profit = 0;

      for (const r of data) {
        for (const i of selectedIds) {
          if (r.id === i) {
            cost += r.cost;
            profit += r.profit;
            break;
          }
        }     
      }

      setCost(cost);
      setProfit(profit);
      setTotal(cost + profit);
      setProfitAbility(new Intl.NumberFormat('zh-CN', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(profit / cost));
    } else {
      let url = '/api/v1/orders/statistics?';
      let querysArray = [];
      for (const k in filterValues) {
        querysArray.push(`${k}=${filterValues[k]}`);
      }

      const querys = url + querysArray.join('&');
    
      fetch(querys, { method: 'GET' })
        .then(resp => resp.json())
        .then(data => {
          setCost(data.cost);
          setProfit(data.profit);
          setTotal(data.cost + data.profit);
          setProfitAbility(new Intl.NumberFormat('zh-CN', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(data.profit / data.cost));
        });
    }
  }, [filterValues, selectedIds, data]);

  return (
    <div>
      <div style={{ display: 'flex', padding: '1em', position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#fff' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={AccountBalanceWalletIcon}
            title="成本"
            subtitle={`¥ ${cost}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={ShoppingCartIcon}
            title="利润"
            subtitle={`¥ ${profit}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={YenIcon}
            title="成本 + 利润"
            subtitle={`¥ ${total}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={PercentIcon}
            title="利润率"
            subtitle={`${profitAbility}`}
          />
        </div>
      </div>
      <Fragment>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map(choice => (
            <Tab
              key={choice.id}
              label={
                <span>
                  {choice.name} (
                  <Count
                    filter={{
                      ...filterValues,
                      status: choice.id,
                    }}
                    sx={{ lineHeight: 'inherit' }}
                  />
                  )
                </span>
              }
              value={choice.id}
            />
          ))}
        </Tabs>
        {filterValues.status === "-1" && (
          <DatagridConfigurable
            rowClick="edit"
            bulkActionButtons={<OrderListBulkActionButtons />}
            sx={{
              '& .RaDatagrid-headerCell': { top: '110px' },
            }}
          >
            <NumberField
              label="编号"
              source="id"
              options={{
                useGrouping: false,
              }}
            />
            <NumberField
              label="成本"
              source="cost"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润"
              source="profit"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润率"
              source="profit_ability"
              sortable={true}
              options={{
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
            <SelectField
              label="状态"
              source="status"
              sortable={true}
              choices={[
                { id: 0, name: '未下订' },
                { id: 1, name: '已下订' },
                { id: 2, name: '已交易' },
              ]}
            />
            <ReferenceField
              label="支付方式"
              source="payment_method"
              reference="payment_methods"
              link={false}
            />
            <ImageField
              label="图片"
              source="image_url"
            />
            <TextField
              label="备注"
              source="description"
              sortable={false}
            />
            <DateField
              label="创建时间"
              source="created_at"
            />
          </DatagridConfigurable>
        )}
        {filterValues.status === "0" && (
          <DatagridConfigurable
            bulkActionButtons={<OrderListBulkActionButtons />}
          >
            <NumberField
              label="编号"
              source="id"
              options={{
                useGrouping: false,
              }}
            />
            <NumberField
              label="成本"
              source="cost"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润"
              source="profit"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润率"
              source="profit_ability"
              sortable={true}
              options={{
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
            <ImageField
              label="图片"
              source="image_url"
            />
            <TextField
              label="备注"
              source="description"
              sortable={false}
            />
            <DateField
              label="创建时间"
              source="created_at"
            />
            <EditButton />
            <OrderConfirmButton />
          </DatagridConfigurable>
        )}
        {filterValues.status === "1" && (
          <DatagridConfigurable
            // bulkActionButtons={<ConfirmedOrderBulkActionButtions />}
            bulkActionButtons={<OrderListBulkActionButtons />}
          >
            <NumberField
              label="编号"
              source="id"
              options={{
                useGrouping: false,
              }}
            />
            <NumberField
              label="成本"
              source="cost"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润"
              source="profit"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润率"
              source="profit_ability"
              sortable={true}
              options={{
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
            <ImageField
              label="图片"
              source="image_url"
            />
            <TextField
              label="备注"
              source="description"
              sortable={false}
            />
            <DateField
              label="下订时间"
              source="confirmed_at"
            />
            <EditButton />
          </DatagridConfigurable>
        )}
        {filterValues.status === "2" && (
          <DatagridConfigurable
            rowClick={"edit"}
            bulkActionButtons={<OrderListBulkActionButtons />}
          >
            <NumberField
              label="编号"
              source="id"
              options={{
                useGrouping: false,
              }}
            />
            <NumberField
              label="成本"
              source="cost"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润"
              source="profit"
              options={{
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }}
              sx={{ fontWeight: 'bold' }}
            />
            <NumberField
              label="利润率"
              source="profit_ability"
              sortable={true}
              options={{
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
            <ReferenceField
              label="支付方式"
              source="payment_method"
              reference="payment_methods"
              link={false}
            />
            <ImageField
              label="图片"
              source="image_url"
            />
            <TextField
              label="备注"
              source="description"
              sortable={false}
            />
            <DateField
              label="下订时间"
              source="confirmed_at"
            />
            <DateField
              label="完成时间"
              source="finished_at"
            />
          </DatagridConfigurable>
        )}
      </Fragment>
    </div>
  );
};
*/

const TabbedData = (props: 
  {role: string, status: string}
) => {
  let r = (<div></div>)

  if (props.role === "观察者") {
    if (props.status === "-1") {
      r = (
        <DatagridConfigurable
          rowClick="edit"
          bulkActionButtons={<OrderListBulkActionButtons />}
          sx={{
            '& .RaDatagrid-headerCell': { top: '110px' },
          }}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <SelectField
            label="状态"
            source="status"
            sortable={true}
            choices={[
              { id: 0, name: '未下订' },
              { id: 1, name: '已下订' },
              { id: 2, name: '已交易' },
            ]}
          />
          <ReferenceField
            label="支付方式"
            source="payment_method"
            reference="payment_methods"
            link={false}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="创建时间"
            source="created_at"
          />
        </DatagridConfigurable>
      );
    } else if (props.status === "0") {
      r = (
        <DatagridConfigurable
          rowClick="edit"
          bulkActionButtons={<OrderListBulkActionButtons />}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="创建时间"
            source="created_at"
          />
        </DatagridConfigurable>
      );
    } else if (props.status === "1") {
      r = (
        <DatagridConfigurable
          rowClick="edit"
          bulkActionButtons={<OrderListBulkActionButtons />}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="下订时间"
            source="confirmed_at"
          />
        </DatagridConfigurable>
      );
    } else if (props.status === "2") {
      r = (
        <DatagridConfigurable
          rowClick={"edit"}
          bulkActionButtons={<OrderListBulkActionButtons />}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <ReferenceField
            label="支付方式"
            source="payment_method"
            reference="payment_methods"
            link={false}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="下订时间"
            source="confirmed_at"
          />
          <DateField
            label="完成时间"
            source="finished_at"
          />
        </DatagridConfigurable>
      );
    }
  } else {
    if (props.status === "-1") {
      r = (
        <DatagridConfigurable
          rowClick="edit"
          bulkActionButtons={<OrderListBulkActionButtons />}
          sx={{
            '& .RaDatagrid-headerCell': { top: '110px' },
          }}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <SelectField
            label="状态"
            source="status"
            sortable={true}
            choices={[
              { id: 0, name: '未下订' },
              { id: 1, name: '已下订' },
              { id: 2, name: '已交易' },
            ]}
          />
          <ReferenceField
            label="支付方式"
            source="payment_method"
            reference="payment_methods"
            link={false}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="创建时间"
            source="created_at"
          />
        </DatagridConfigurable>
      );
    } else if (props.status === "0") {
      r = (
        <DatagridConfigurable
          bulkActionButtons={<OrderListBulkActionButtons />}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="创建时间"
            source="created_at"
          />
          <EditButton />
          <OrderConfirmButton />
        </DatagridConfigurable>
      );
    } else if (props.status === "1") {
      r = (
        <DatagridConfigurable
          bulkActionButtons={<OrderListBulkActionButtons />}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="下订时间"
            source="confirmed_at"
          />
          <EditButton />
        </DatagridConfigurable>
      );
    } else if (props.status === "2") {
      r = (
        <DatagridConfigurable
          rowClick={"edit"}
          bulkActionButtons={<OrderListBulkActionButtons />}
        >
          <NumberField
            label="编号"
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            label="成本"
            source="cost"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润"
            source="profit"
            options={{
              style: 'currency',
              currency: 'CNY',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            sx={{ fontWeight: 'bold' }}
          />
          <NumberField
            label="利润率"
            source="profit_ability"
            sortable={true}
            options={{
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <ReferenceField
            label="支付方式"
            source="payment_method"
            reference="payment_methods"
            link={false}
          />
          <ImageField
            label="图片"
            source="image_url"
          />
          <TextField
            label="备注"
            source="description"
            sortable={false}
          />
          <DateField
            label="下订时间"
            source="confirmed_at"
          />
          <DateField
            label="完成时间"
            source="finished_at"
          />
        </DatagridConfigurable>
      );
    }
  }

  return r;
}

const TabbedDatagrid = () => {
  const role = JSON.parse(localStorage.getItem("auth") ?? "{type:\"未知\"}").type

  const [cost, setCost] = useState(0);
  const [profit, setProfit] = useState(0);
  const [total, setTotal] = useState(0);
  const [profitAbility, setProfitAbility] = useState('0%');

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters, data, selectedIds } = listContext;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters && setFilters(
        { ...filterValues, status: value },
        displayedFilters,
        false  
      );
    },
    [displayedFilters, filterValues, setFilters]
  );

  useEffect(() => {
    if (selectedIds.length > 0) {
      let cost = 0;
      let profit = 0;

      for (const r of data) {
        for (const i of selectedIds) {
          if (r.id === i) {
            cost += r.cost;
            profit += r.profit;
            break;
          }
        }     
      }

      setCost(cost);
      setProfit(profit);
      setTotal(cost + profit);
      setProfitAbility(new Intl.NumberFormat('zh-CN', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(profit / cost));
    } else {
      let url = '/api/v1/orders/statistics?';
      let querysArray = [];
      for (const k in filterValues) {
        querysArray.push(`${k}=${filterValues[k]}`);
      }

      const querys = url + querysArray.join('&');
    
      fetch(querys, { method: 'GET' })
        .then(resp => resp.json())
        .then(data => {
          setCost(data.cost);
          setProfit(data.profit);
          setTotal(data.cost + data.profit);
          setProfitAbility(new Intl.NumberFormat('zh-CN', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(data.profit / data.cost));
        });
    }
  }, [filterValues, selectedIds, data]);

  let t = (
    <div>
      <div style={{ display: 'flex', padding: '1em', position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#fff' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={AccountBalanceWalletIcon}
            title="成本"
            subtitle={`¥ ${cost}`}
          />
        </div>
        <Spacer /> 
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={ShoppingCartIcon}
            title="利润"
            subtitle={`¥ ${profit}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={YenIcon}
            title="成本 + 利润"
            subtitle={`¥ ${total}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={PercentIcon}
            title="利润率"
            subtitle={`${profitAbility}`}
          />
        </div>
      </div>
      <Fragment>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map(choice => (
            <Tab
              key={choice.id}
              label={
                <span>
                  {choice.name} (
                    <Count
                      filter={{
                        ...filterValues,
                        status: choice.id,
                      }}
                      sx={{ lineHeight: 'inherit' }}
                    />
                  )
                </span>
              }
              value={choice.id}
            />
          ))}
        </Tabs>
        <TabbedData
          role={role}
          status={filterValues.status}
        />
      </Fragment>
    </div>
  )

  return t;
}

export default OrderList;
