import OrderIcon from '@mui/icons-material/AttachMoney';

import OrderList from './OrderList';
import OrderEdit from './OrderEdit';
import OrderCreate from './OrderCreate';

/*
export const orderLifecycleCallbacks = {
  resource: 'orders',
  afterGetList: async (result: any, dataProvider: any) => {
    console.log(result);

    return result;
  },
};
*/

export default {
  list: OrderList,
  edit: OrderEdit,
  create: OrderCreate,

  icon: OrderIcon,
};
