import UserIcon from '@mui/icons-material/Person';

import UserEdit from './UserEdit';
import UserList from './UserList';
import UserCreate from './UserCreate';

export default {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,

  icon: UserIcon,
};