import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCard from '@mui/icons-material/CreditCard'
import CreditScore from '@mui/icons-material/CreditScore'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import YenIcon from "@mui/icons-material/CurrencyYen";
import { useEffect, useState } from 'react';
import CardWithIcon from "./utils/CardWithIcon";
import CostChart from './utils/CostChart';
import { useMediaQuery, useTheme } from '@mui/material';

const Spacer = () => <span style={{ width: '1em' }} />

const Dashboard = () => {
  const [monthlyProfit, setMonthlyProfit] = useState(0);
  const [monthlyConfirmedProfit, setMonthlyConfirmedProfit] = useState(0);
  const [unfinishedProfit, setUnfinishedProfit] = useState(0);
  const [inStockCost, setInStockCost] = useState(0);
  const [finishedCost, setFinishedCost] = useState(0);
  const [finishedProfitAbility, setFinishedProfitAbility] = useState('0%');
  const [totalCostChart, setTotalCostChart] = useState([]);

  /*
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  */

  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    fetch(`/api/v1/dashboard`, { method: 'GET' })
      .then(resp => resp.json())
      .then(data => {
        setMonthlyProfit(data.monthly_profit);
        setMonthlyConfirmedProfit(data.monthly_confirmed_profit);
        setUnfinishedProfit(data.unfinished_profit);
        setInStockCost(data.in_stock_cost);
        setFinishedCost(data.finished_cost);
        setFinishedProfitAbility(new Intl.NumberFormat('zh-CN', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(data.finished_profit / data.finished_cost));
        setTotalCostChart(data.total_cost_chart.map((v: any) => {
          return {
            date: v.date,
            cost: v.total_cost,
          };
        }));
      });
  }, []);

  // let role = JSON.parse(localStorage.getItem("auth") ?? "{type:\"未知\"}").type

  return isXSmall ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={CreditCard}
            title="本月入账利润"
            subtitle={`¥ ${monthlyConfirmedProfit}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={CreditScore}
            title="本月已交易利润"
            subtitle={`¥ ${monthlyProfit}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={ShoppingCartIcon}
            title="未结利润"
            subtitle={`¥ ${unfinishedProfit}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={InventoryIcon}
            title="库存商品金额"
            subtitle={`¥ ${inStockCost}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={YenIcon}
            title="总交易额"
            subtitle={`¥ ${finishedCost}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={AccountBalanceWalletIcon}
            title="总利润率"
            subtitle={`${finishedProfitAbility}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <CostChart
          costs={totalCostChart}
        />
      </div>
    </div>
  ) : isSmall ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', marginTop: '1em', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={CreditCard}
            title="本月入账利润"
            subtitle={`¥ ${monthlyConfirmedProfit}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={CreditScore}
            title="本月已交易利润"
            subtitle={`¥ ${monthlyProfit}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={ShoppingCartIcon}
            title="未结利润"
            subtitle={`¥ ${unfinishedProfit}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={InventoryIcon}
            title="库存商品金额"
            subtitle={`¥ ${inStockCost}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={YenIcon}
            title="总交易额"
            subtitle={`¥ ${finishedCost}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={AccountBalanceWalletIcon}
            title="总利润率"
            subtitle={`${finishedProfitAbility}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <CostChart
          costs={totalCostChart}
        />
      </div>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', marginTop: '1em', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={CreditCard}
            title="本月入账利润"
            subtitle={`¥ ${monthlyConfirmedProfit}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={CreditScore}
            title="本月已交易利润"
            subtitle={`¥ ${monthlyProfit}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={ShoppingCartIcon}
            title="未结利润"
            subtitle={`¥ ${unfinishedProfit}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={InventoryIcon}
            title="库存商品金额"
            subtitle={`¥ ${inStockCost}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={YenIcon}
            title="总交易额"
            subtitle={`¥ ${finishedCost}`}
          />
        </div>
        <Spacer />
        <div style={{ flex: 1 }}>
          <CardWithIcon
            icon={AccountBalanceWalletIcon}
            title="总利润率"
            subtitle={`${finishedProfitAbility}`}
          />
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <CostChart
          costs={totalCostChart}
        />
      </div>
    </div>
  );
};

export default Dashboard;